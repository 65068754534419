import { useTranslation } from "react-i18next";

function Resume() {
    const { t } = useTranslation(); 

    return (
        <div className="rn-resume-area rn-section-gap section-separator" id="resume">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="100" data-aos-once="true" className="section-title text-center">
                            <span className="subtitle">{t('resumeIntro')}</span>
                            <h2 className="title">{t('resumeHeader')}</h2>
                        </div>
                    </div>
                </div>
                <div className="row mt--45 justify-content-center">
                    <div>
                        <a href="files/cv.pdf" download="SinRatha.pdf" className="btn btn-outline-danger btn-block btn-lg" target="_blank" rel="noreferrer">{t('downloadResume')}</a>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Resume;
