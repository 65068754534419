import React from "react";
import { useTranslation } from "react-i18next";

function Service({ service }) {
    const { t } = useTranslation();

    return (
        <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="100" data-aos-once="true" className="col-lg-6 col-xl-4 col-md-6 col-sm-12 col-12 mt--50 mt_md--30 mt_sm--30">
            <div className="rn-service">
                <div className="inner">
                    <div className="icon">
                        {service.icon}
                    </div>
                    <div className="content">
                        <h4 className="title">{t(service.name)}</h4>
                        <p className="description">{t(service.desc)}</p>
                        {service.descList ? (
                            <ul>
                                {service.descList.map(descItem => {
                                    return <li>{t(descItem)}</li>
                                })}
                            </ul>
                        ) : <React.Fragment />}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Service;
