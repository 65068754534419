import { FaAdversal, FaMicroblog } from "react-icons/fa";
import { HiOfficeBuilding } from "react-icons/hi";
import { CgWebsite } from "react-icons/cg";
import { GoDeviceMobile } from "react-icons/go";
import { BiSupport } from "react-icons/bi";



function offeredServices() {
    const services = [];

    services.push({
        id: 1,
        icon: <FaMicroblog/>,
        name: 'services.service1.name',
        desc: 'services.service1.desc',
        descList: ['fbBusinessPage', 'instaBusiness', 'linkedBusiness', 'youTube', 'etc']
    });

    services.push({
        id: 2,
        icon: <FaAdversal  />,
        name: 'services.service2.name',
        desc: 'services.service2.desc',
        descList: ['gAds', 'fbAds', 'linkedInAds', 'youTubeAds', 'etc']
    });

    services.push({
        id: 3,
        icon: <HiOfficeBuilding  />,
        name: 'services.service3.name',
        desc: 'services.service3.desc',
    });

    services.push({
        id: 4,
        icon: <CgWebsite  />,
        name: 'services.service4.name',
        desc: 'services.service4.desc',
    });

    services.push({
        id: 5,
        icon: <GoDeviceMobile  />,
        name: 'services.service5.name',
        desc: 'services.service5.desc',
    });

    services.push({
        id: 6,
        icon: <BiSupport  />,
        name: 'services.service6.name',
        desc: 'services.service6.desc',
    });

    return services;
}

export default offeredServices;
