import {TbBrandFacebook, TbBrandLinkedin, TbBrandTelegram, TbBrandYoutube} from 'react-icons/tb'

function SocialLinks() {
    return (
        <ul className="social-share d-flex liststyle">

            <li><a href="https://www.facebook.com/SinRathadigitalmarketing/" target="_blank" rel="noreferrer"><TbBrandFacebook size={50} /></a>
            </li>
            <li><a href="https://t.me/RathaSIN" target="_blank" rel="noreferrer"><TbBrandTelegram size={50} /></a>
            </li>
            <li><a href="https://www.linkedin.com/in/ratha-sin-99b51523b/" target="_blank" rel="noreferrer"><TbBrandLinkedin size={50} /></a>
            </li>
            <li><a href="https://www.youtube.com/c/travelwithroth" target="_blank" rel="noreferrer"><TbBrandYoutube size={50} /></a>
            </li>
        </ul>
    );
}

export default SocialLinks;
