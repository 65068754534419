import { useTranslation } from "react-i18next";
import SocialLinks from "./SocialLinks";

function MainSection() {
    const { t } = useTranslation(); 

    return (
        <div id="home" className="rn-slider-area">
            <div className="slide slider-style-1">
                <div className="container">
                    <div className="row row--30 align-items-center">
                        <div className="order-2 order-sm-1 col-sm-9 mt_md--50 mt_sm--50 mt_lg--30">
                            <div className="content">
                                <div className="inner">
                                    <span className="subtitle">{t('welcome')}</span>
                                    <h1 className="title">{t('intro1')}
                                        <br />
                                        <span className="firstpage-name" >{t('name')}</span><br />
                                    </h1>

                                    <div>
                                        <p className="description"  dangerouslySetInnerHTML={{__html: t('intro2')}}></p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12">
                                        <div className="social-share-inner-left">
                                            <span className="title">{t('findWithMe')}</span>
                                            <SocialLinks/>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="order-1 order-sm-2 col-sm-3">
                            <img src="assets/images/slider/banner-01.png" alt="Personal Portfolio Images" className="rounded fluid" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MainSection;
