import ContactMe from "./ContactMe";
import Header from "./Header";
import MainSection from "./MainSection";
import Resume from "./Resume";
import Services from "./Services";

function Main() {
  return (
    <div >
      <Header></Header>

      <main className="main-page-wrapper">
          <MainSection/>
          <Services/>
          <Resume/>
          <ContactMe/>
      </main>
    </div>
  );
}

export default Main;
