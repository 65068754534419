import { useTranslation } from "react-i18next";
import SocialLinks from "./SocialLinks";

function ContactMe() {
    const { t } = useTranslation(); 

    return (
        <div className="rn-contact-area rn-section-gap section-separator" id="contacts">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title text-center">
                            <span className="subtitle">{t('contactIntro')}</span>
                            <h2 className="title">{t('contactWithMe')}</h2>
                        </div>
                    </div>
                </div>
                <div className="row mt--50 mt_md--40 mt_sm--40 mt-contact-sm justify-content-center">
                    <div className="col-lg-5">
                        <div className="contact-about-area">
                            <div className="thumbnail">
                                <img src="assets/images/contact/contact1.png" alt="contact-img" />
                            </div>
                            <div className="title-area">
                                <h4 className="title">{t('name')}</h4>
                            </div>
                            <div className="description">
                                <p>{t('getInTouch')}
                                </p>
                                <span className="phone">{t('phone')}: <a href="tel:0085566569999">+(855) 6656-9999</a></span>
                                <span className="mail">{t('email')}: <a href="mailto:ceo@rathasin.com">ceo@rathasin.com</a></span>
                            </div>
                            <div className="social-area">
                                <div className="name">{t('findWithMe')}</div>
                                <div className="social-icone">
                                    <SocialLinks />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactMe;
