import { useTranslation } from "react-i18next";
import offeredServices from "../OfferedServices";
import Service from "./Service";

function Services() {
    const { t } = useTranslation(); 

    const services = offeredServices();
    return (
        <div className="rn-service-area rn-section-gap section-separator" id="features">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title text-left" data-aos="fade-up" data-aos-duration="500" data-aos-delay="100" data-aos-once="true">
                            <span className="subtitle">{t('features')}</span>
                            <h2 className="title">{t('servicesHeader')}</h2>
                        </div>
                    </div>
                </div>
                <div className="row row--25 mt_md--10 mt_sm--10">

                    {
                        services.map((service)=>{
                            return (<Service service={service}></Service>);
                        })

                    }
                </div>
            </div>
        </div>
    );
}

export default Services;
