import { useState } from "react";
import i18n from "../i18n";

const languages = [
    { value: 'en', text: "English" },
    { value: 'kh', text: "ខ្មែរ ( Khmer )" }
]


function LangSelector() {
      
    const [lang, setLang] = useState('en');

    const handleChange = e => { 
        i18n.changeLanguage(e.target.value);
        setLang(e.target.value);//Just to refresh the page
    }
    console.info(lang);
    return (
        <select value={i18n.language} onChange={handleChange}>
            {languages.map(item => {
                return (<option key={item.value}
                    value={item.value}>{item.text}</option>);
            })}
        </select>
    );
}

export default LangSelector;
