import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FiMenu } from 'react-icons/fi';
import LangSelector from "./LangSelector";

import SocialLinks from "./SocialLinks";
function Header() {
  const [mobMenuOpen, setMobMenuOpen] = useState(false);

  const _onMobileMenuClick = (event) => {
    event.preventDefault();
    setMobMenuOpen(false);
  }
  const { t } = useTranslation(); 

  return (
      <header className="rn-header haeder-default black-logo-version header--fixed header--sticky">
        <div className="header-wrapper rn-popup-mobile-menu m--0 row align-items-center" >
          <div className="order-1 order-sm-3 col-lg-12 col-6 ">
            <div className="header-center">
              <nav id="sideNav" className="mainmenu-nav navbar-example2 d-none d-xl-block">
                <ul className="primary-menu nav nav-pills">
                  <li className="nav-item"><a className="nav-link smoth-animation active" href="#home">{t('home')}</a></li>
                  <li className="nav-item"><a className="nav-link smoth-animation" href="#features">{t('features')}</a></li>
                  <li className="nav-item"><a className="nav-link smoth-animation" href="#resume">{t('resume')}</a></li>
                  <li className="nav-item"><a className="nav-link smoth-animation" href="#contacts">{t('contact')}</a></li>
                </ul>
              </nav>
              <div className="m-4  d-none d-xl-block">
                <LangSelector />
              </div>
              <div className="header-right">
                <div className="humberger-menu d-block d-xl-none" onClick={() => setMobMenuOpen(true)}>
                  <FiMenu id="menuBtn" className="feather-menu humberger-menu" size={36}></FiMenu>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={mobMenuOpen ? "popup-mobile-menu menu-open" : "popup-mobile-menu"} onClick={_onMobileMenuClick}>
          <div className="inner">
            <div className="menu-top">
              <div className="menu-header">

              </div>
            </div>
            <div className="content"  onClick={(e)=>e.stopPropagation()}>
              <LangSelector />
              <ul className="primary-menu nav nav-pills" onClick={_onMobileMenuClick}>
              <li className="nav-item"><a className="nav-link smoth-animation active" href="#home">{t('home')}</a></li>
                  <li className="nav-item"><a className="nav-link smoth-animation" href="#features">{t('features')}</a></li>
                  <li className="nav-item"><a className="nav-link smoth-animation" href="#resume">{t('resume')}</a></li>
                  <li className="nav-item"><a className="nav-link smoth-animation" href="#contacts">{t('contact')}</a></li>
              </ul>
              <div className="social-share-style-1 mt--40">
                <span className="title">{t('findWithMe')}</span>
                <SocialLinks />
              </div>
            </div>
          </div>
        </div>
      </header>
  );
}

export default Header;
